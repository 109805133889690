.ui.primary.button {
    background-color: #FFE200;
    color: #000;
}

.ui.primary.button:hover,
.ui.primary.button:focus {
    color: #FFE200;
    background-color: #000;
}

.ui.header .circular.icon {
    color: #FFE200;
    background-color: rgb(255 226 0 / 10%);
}

.admin-layout__nav .item.active,
#sidebar.ui.sidebar.vertical.menu .item.active {
    background-color: #FFE200 !important;
    color: #000 !important;
}

.ui.toggle.checkbox input:checked ~ .box::before,
.ui.toggle.checkbox input:checked ~ label::before,
.ui.toggle.checkbox input:focus:checked ~ .box::before,
.ui.toggle.checkbox input:focus:checked ~ label::before {
    background-color: #FFE200 !important;
}

.ui.teal.labels .label, .ui.teal.label {
    background-color: #FFE200 !important;
    border-color: #FFE200 !important;
    color: #000 !important;
}

.attribute-input *:not(:last-child) {
  margin-right: 0 !important;
}
.attribute-input *:not(:first-child) {
  margin-left: 0 !important;
}

.border-bottom:after {
    content: "";
    display: block;
    width: 100%;
    background-color: #e1e1e1;
    height: 8px;
}

.field p.small {
  font-size: 11px;
}


.field table {
  border: 1px solid #CCC;
  border-collapse: collapse;
}
.field table td {
  border: 1px solid #CCC;
  border-collapse: collapse;
}

.help-text {
  margin: 10px 0;
}

#sylius_channel_productVariants [data-form-collection=list]  {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap:10px;
  margin-bottom: 10px;
}
#sylius_channel_productVariants [data-form-collection=item] {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
#sylius_channel_productVariants [data-form-collection=item] .sort {
  cursor: grab;
}
#sylius_channel_productVariants [data-form-collection=item] > div {
  width: 100%;
}
#sylius_channel_productVariants [data-form-collection=item] .field {
  margin-bottom: 0;
}
#sylius_channel_productVariants [data-form-collection=item] .field label {
  display: none;
}
#sylius_channel_productVariants [data-form-collection=item] .field input[type=number]{
  display: none;
}
#sylius_channel_productVariants [data-form-collection=delete] {
  margin: 0 !important;
  padding: 10px 20px !important;
  text-indent: -9999em;
}
#sylius_channel_productVariants [data-form-collection=delete] i {
  text-indent: initial;
  width: 35px !important;
}

.ui-state-highlight {
  height: 40px;
  background-color: rgb(224, 225, 226);
  border: 1px solid #CACBCD;
}
#app_product_bundle_productBundleItems [data-form-collection=list]  {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap:10px;
  margin-bottom: 10px;
}
#app_product_bundle_productBundleItems [data-form-collection=item] {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
#app_product_bundle_productBundleItems [data-form-collection=item] > div {
  width: 100%;
}
#app_product_bundle_productBundleItems [data-form-collection=item] > div .grouped.fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
#app_product_bundle_productBundleItems [data-form-collection=item] > div .grouped.fields .field {
  width: 300px;
}
